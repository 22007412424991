<template>
  <div class="login-content">
    <div class="logo">
      <img
        src="../../../assets/image/登录页/科研人PC登录logo.png"
        alt=""
      >
    </div>
    <div class="z_title">
      <div :class="msg == 0 ? 'a active':'a'" @click="msg = 0">验证码登录</div>
      <div :class="msg == 1 ? 'a active':'a'" @click="msg = 1">账户登录</div>
    </div>
    <div class="form" v-if="msg === 0">
      <div class="err-message">
        {{nameErrMessage}}
      </div>
      <div
        class="username"
        :class="{errName:!isUserNameRight}"
      >
        <img
          src="../../../assets/image/登录页/姓名.png"
          alt=""
        >
        <input
          type="text"
          v-model="usermobile"
          minlength="11"
          maxlength="11"
          @blur="formitemtest('username')"
          @focus="formitemreset('username')"
        >
      </div>
      <div class="err-message">
        {{passwordErrMessage}}
      </div>
            <div class="z_yzm">
      <div
        class="password"
        :class="{errPassWord:!isUserPassWordRight1}"
      >
        <img
          src="../../../assets/image/登录页/密码1.png"
          alt=""
        >
        <input
            id="code"
            type="text"
            @focus="getfocus($event,'usercode')"
            @blur="getblur($event,'usercode')"
            v-model="usercode"
            oninput="value=value.replace(/[^\d]/g,'')"
            maxlength="6"
          >
      </div>
      <el-button class="anniu"  v-show="show"
              @click="tosendthecode">获取验证码</el-button>
        <el-button class="anniu" v-show="!show"
              >{{count}} s</el-button>
            </div>
      <div
        class="login-button"
        @click="clicktologin1()"
      >
        登录
      </div>
      <div class="to-registed">
        还没有账号？点击这里<span @click="toRegistered">注册</span>
      </div>

    </div>

     <div class="form" v-if="msg === 1">
      <div class="err-message">
        {{nameErrMessage}}
      </div>
      <div
        class="username"
        :class="{errName:!isUserNameRight}"
      >
        <img
          src="../../../assets/image/登录页/姓名.png"
          alt=""
        >
        <input
          type="text"
          v-model="userName"
          minlength="11"
          maxlength="11"
          @blur="formitemtest('username')"
          @focus="formitemreset('username')"
        >
      </div>
      <div class="err-message">
        {{passwordErrMessage}}
      </div>
        <div
        class="password"
        :class="{errPassWord:!isUserPassWordRight}"
      >
        <img
          src="../../../assets/image/登录页/密码.png"
          alt=""
        >
        <input
          type="password"
          v-model="passWord"
          @blur="formitemtest('password')"
          @focus="formitemreset('password')"
        >
      </div>
      <div
        class="login-button"
        @click="clicktologin()"
      >
        登录
      </div>
      <div class="forget">
        <div class="forget-left">
          <div
            class="radio"
            @click="radioclick"
            :class="{styleOfChecked:radioischecked}"
          >
            <!-- <img
              v-show="radioischecked"
              src="../../../assets/image/登录页/对勾.png"
              alt=""
            > -->
          </div>
          <div class="text">记住密码</div>
        </div>
        <div
          class="forget-right"
          @click="toresetpassword"
        >
          重置密码
        </div>
      </div>
      <div class="to-registed">
        还没有账号？点击这里<span @click="toRegistered">注册</span>
      </div>

    </div>

  </div>
</template>

<script>
import { toLogined,toLogined1, getProuser } from '../../../api/login/login.js'
import { toRegistered, getSMScode } from '../../../api/login/registered'
export default {
  data () {
    return {
      // 是否记住密码
      radioischecked: true,
      // 倒计时实现
      show: true,
      count: '',
      timer: null,
      // 用户手机号
      usermobile: '',
      // 用户验证码
      usercode: '',
      // 姓名
      userName: '',
      // 密码
      passWord: '',
      // 验证码
      passWord1: '',
      // 错误信息
      // 用户名
      nameErrMessage: '',
      // 密码
      passwordErrMessage: '',

      // 账号是否验证通过
      isUserNameRight: true,
      // 密码是否验证通过
      isUserPassWordRight: true,
      // 验证码是否正确
      isUserPassWordRight1: true,
      msg:0
    }
  },
  mounted () {
    if (localStorage.getItem('useraccount')) {
      this.$data.userName = localStorage.getItem('useraccount')
      this.$data.passWord = localStorage.getItem('userpassWord')
    }
  },
  methods: {
    radioclick () {
      const that = this
      if (this.$data.radioischecked) {
        this.$data.radioischecked = !this.$data.radioischecked
        localStorage.removeItem('useraccount', that.$data.userName)
        localStorage.removeItem('userpassWord', that.$data.passWord)
      } else {
        this.$data.radioischecked = !this.$data.radioischecked
      }
    },
    // 跳转到注册
    toRegistered () {
      this.$router.push({
        path: 'registered'
      })
    },
    // 表单验证
    formitemtest (item) {
      const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/

      const regMobile = /^(0|86|17951)?(10[0-9]|11[0-9]|12[0-9]|13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])[0-9]{8}$/

      // * 至少包含数字跟字母，可以有字符
      //          * /
      // const regPassWord = /(?=.*([a-zA-Z].*))(?=.*[0-9].*)[a-zA-Z0-9-*/+.~!@#$%^&*()]{6,20}$/
      if (item === 'username') {
        if (this.$data.userName === '') {

        } else {
          if (regMobile.test(this.$data.userName) || regEmail.test(this.$data.userName)) {
            console.log('验证通过')
            this.$data.isUserNameRight = true
            this.$data.nameErrMessage = ''
          } else {
            console.log('验证失败')
            this.$data.isUserNameRight = false
            this.$data.nameErrMessage = '验证失败'
          }
        }
      } else if (item === 'password') {
        if (this.$data.isUserNameRight) {
          if (this.$data.passWord === '') {

          } else {
            // eslint-disable-next-line no-constant-condition
            if (true) {
              console.log('密码验证通过')
              this.$data.isUserPassWordRight = true
              this.$data.passwordErrMessage = ''
            } else {
              console.log('密码验证失败')
              this.$data.isUserPassWordRight = false
              this.$data.passwordErrMessage = '密码至少包含数字跟字母，可以有字符'
            }
          }
        }
      }
    },
    // 获取焦点重置样式
    formitemreset (item) {
      if (item === 'username') {
        this.$data.isUserNameRight = true
        this.$data.nameErrMessage = ''
      } else if (item === 'password') {
        this.$data.isUserPassWordRight = true
        this.$data.passwordErrMessage = ''
      }
    },
    getfocus (event, str) {
      event.currentTarget.previousElementSibling.classList.add('gd')
      const that = this
      if (str === 'usermobile') {
        that.$data.usermobileerrmessage = ''
      } else if (str === 'useremail') {
        that.$data.useremailerrmessage = ''
      } else if (str === 'userpassword') {
        that.$data.userpassworderrmessage = ''
      }
    },
    getblur (event, str) {
      const that = this
      if (str === 'usermobile') {
        if (that.$data.usermobile === '') {
          event.currentTarget.previousElementSibling.classList.remove('gd')
        } else {
          // const regMobile = /^(0|86|17951)?(10[0-9]|11[0-9]|12[0-9]|13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])[0-9]{8}$/
          // if (regMobile.test(that.$data.usermobile)) {

          // } else {
          //   that.$data.usermobileerrmessage = '手机号格式错误'
          // }
        }
      } else if (str === 'usercode') {
        if (that.$data.usercode === '') {
          event.currentTarget.previousElementSibling.classList.remove('gd')
        }
      } else if (str === 'useremail') {
        if (that.$data.useremail === '') {
          event.currentTarget.previousElementSibling.classList.remove('gd')
        } else {
          const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/
          if (regEmail.test(that.$data.useremail)) {

          } else {
            that.$data.useremailerrmessage = '邮箱格式错误'
          }
        }
      } else if (str === 'entityName') {
        if (that.$data.entityName === '') {
          event.currentTarget.previousElementSibling.classList.remove('gd')
        }
      } else if (str === 'userpassword') {
        if (that.$data.userpassword === '') {
          event.currentTarget.previousElementSibling.classList.remove('gd')
        } else {
          const regPassWord = /(?=.*([a-zA-Z].*))(?=.*[0-9].*)[a-zA-Z0-9-*/+.~!@#$%^&*()]{6,20}$/
          if (regPassWord.test(that.$data.userpassword)) {

          } else {
            that.$data.userpassworderrmessage = '密码至少包含数字跟字母'
          }
        }
      }
    },
     // 获取短信验证码
    tosendthecode () {
      const that = this
      if (this.$data.usermobile === '') {
        this.$message.error('请填写手机号')
      } else {
        getSMScode(that.$data.usermobile).then(res => {
          that.$message({
            message: '验证码发送成功',
            type: 'success'
          })
          const TIME_COUNT = 60
          if (!this.timer) {
            this.count = TIME_COUNT
            this.show = false
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--
              } else {
                this.show = true
                clearInterval(this.timer)
                this.timer = null
              }
            }, 1000)
          }
          console.log('短信验证码发送成功', res)
        }, err => {
          that.$message.error('验证码发送失败')
          console.log('短信验证码发送失败', err)
        })
      }
    },
     // 验证码点击登录
    clicktologin1 () {
      const that = this
      if (that.$data.usermobile === '' || that.$data.usercode === '') {
        that.$message({
          message: '用户名或验证码不能为空',
          type: 'warning'
        })
      } else {
        if (that.$data.nameErrMessage === '' && that.$data.passwordErrMessage === '') {
          const params = {
            mobile: that.$data.usermobile,
            code: that.$data.usercode
          }
          toLogined1(params).then(res => {
            console.log('登录返回res', res)
            if (res.code === 1) {
              that.$message({
                message: res.msg,
                type: 'success'
              })
              if (that.$data.radioischecked) {
                localStorage.setItem('useraccount', that.$data.userName)
                localStorage.setItem('userpassWord', that.$data.passWord)
              } else {
                localStorage.removeItem('useraccount', that.$data.userName)
                localStorage.removeItem('userpassWord', that.$data.passWord)
              }
              const usermessage = JSON.stringify(res.data.data)
              localStorage.setItem('usermessage', usermessage)
              localStorage.setItem('setLoginStatus', true)
              that.$store.commit('setLoginStatus', true)
              that.$store.commit('setUserid', res.data.data.user_id)
              getProuser({ user_id: res.data.data.user_id }).then(res => {
                console.log('用户是否为pro会员', res)
                // eslint-disable-next-line eqeqeq
                that.$store.commit('setvip_time_new', res.data.vip_time_new)
                
                if (res.data.level == 3) {
                  localStorage.setItem('ispro', true)
                  that.$store.commit('setispro', true)
                } else {
                  localStorage.setItem('ispro', false)
                  that.$store.commit('setispro', false)
                }
                setTimeout(() => {
                  localStorage.setItem('navActive', 1)
                  this.$store.commit('setnavActive', 1)
                  that.$router.push({ path: '/pc/content/homePage', query: { login: 'true' } })
                }, 1000)
              }, err => {
                console.log('获取会员信息异常', err)
              })
            } else {
              that.$message({
                message: res.data.data,
                type: 'warning'
              })
            }
          }, err => {
            console.log('登录异常', err)
          })
        }
      }
    },
    // 登录按钮点击事件
    clicktologin () {
      const that = this
      if (that.$data.userName === '' || that.$data.passWord === '') {
        that.$message({
          message: '用户名或密码不能为空',
          type: 'warning'
        })
      } else {
        if (that.$data.nameErrMessage === '' && that.$data.passwordErrMessage === '') {
          const params = {
            user_login: that.$data.userName,
            user_pass: that.$data.passWord
          }
          toLogined(params).then(res => {
            console.log('登录返回res', res)
            if (res.code === 1) {
              that.$message({
                message: res.msg,
                type: 'success'
              })
              if (that.$data.radioischecked) {
                localStorage.setItem('useraccount', that.$data.userName)
                localStorage.setItem('userpassWord', that.$data.passWord)
              } else {
                localStorage.removeItem('useraccount', that.$data.userName)
                localStorage.removeItem('userpassWord', that.$data.passWord)
              }
              const usermessage = JSON.stringify(res.data.data)
              localStorage.setItem('usermessage', usermessage)
              localStorage.setItem('setLoginStatus', true)
              that.$store.commit('setLoginStatus', true)
              debugger
              that.$store.commit('setUserid', res.data.data.id)
              getProuser({ user_id: res.data.data.id }).then(res => {
                console.log('用户是否为pro会员', res)
                // eslint-disable-next-line eqeqeq
                 that.$store.commit('setvip_time_new', res.data.vip_time_new)
                if (res.data.level == 3) {
                  localStorage.setItem('ispro', true)
                  that.$store.commit('setispro', true)
                } else {
                  localStorage.setItem('ispro', false)
                  that.$store.commit('setispro', false)
                }
                setTimeout(() => {
                  localStorage.setItem('navActive', 1)
                  this.$store.commit('setnavActive', 1)
                  that.$router.push({ path: '/pc/content/homePage', query: { login: 'true' } })
                }, 1000)
              }, err => {
                console.log('获取会员信息异常', err)
              })
            } else {
              that.$message({
                message: res.data.data,
                type: 'warning'
              })
            }
          }, err => {
            console.log('登录异常', err)
          })
        }
      }
    },
    // 忘记密码点击事件
    toresetpassword () {
      this.$router.push({
        path: 'resetpassword'
      })
    }
  }

}
</script>

<style lang="scss" scoped>
.login-content {
  font-size: 12px;
  width: 660px;
  height: 700px;
  margin: 102px auto;
  background-color: #fff;
  box-sizing: border-box;
  padding: 54px 125px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 102px;
  .logo {
    margin-top: 30px;
    margin-bottom: 60px;
    img {
      // height: 76px;
    }
  }
  .z_title{display: flex;align-items: center;justify-content: center;border-bottom:1px solid #E8E8E8;margin-bottom:40px;width:100%;
    .a{padding-bottom: 12px;font-size: 16px;color: #444444;border-bottom: 2px  solid transparent;margin:0 30px;cursor: pointer;
      &.active{color: #5E068C;border-color: #5E068C;}
    }
  }
  .err-message {
    font-size: 14px;
    height: 26px;
    color: red;
    padding-bottom: 10px;
    box-sizing: border-box;
  }
  .username {
    border-radius: 8px;
    border: 1px solid #f1f3f5;
    box-sizing: border-box;
    padding: 0 18px;
    width: 410px;
    height: 56px;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    img {
      width: 16.76px;
      height: 22px;
      margin-right: 32px;
    }
    input {
      flex: 1;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #444444;
      border: 0px;
      outline: 0px;
    }
  }
  .password {
    border-radius: 8px;
    border: 1px solid #f1f3f5;
    box-sizing: border-box;
    padding: 0 18px;
    width: 410px;
    height: 56px;
    display: flex;
    align-items: center;
    margin-bottom: 42px;
    img {
      width: 18.86px;
      height: 22px;
      margin-right: 32px;
    }
    input {
      flex: 1;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #444444;
      border: 0px;
      outline: 0px;
    }
  }
  .errName {
    border: 1px solid red;
  }
  .errPassWord {
    border: 1px solid red;
  }
  .login-button {
    cursor: pointer;
    width: 410px;
    height: 56px;
    background: #5e068c;
    opacity: 1;
    border-radius: 4px;
    color: #fff;
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }
  .forget {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .forget-left {
      display: flex;
      justify-content: left;
      align-items: center;
      .styleOfChecked {
        background-image: url("../../../assets/image/登录页/对勾.png") !important;
        background-repeat: no-repeat !important;
        background-position: 50% 50% !important;
        background-size: 16.34px 11.57px !important;
      }
      .radio {
        cursor: pointer;
        width: 28px;
        height: 28px;
        background: #ffffff;
        border: 1px solid #c4c4c4;
        opacity: 1;
        border-radius: 4px;
        margin-right: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 16.34px;
          height: 11.57px;
        }
      }
      .text {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
      }
    }
    .forget-right {
      cursor: pointer;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
    }
  }
  .to-registed {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 21px;
    color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      cursor: pointer;
      color: #990263;
    }
  }
}

.z_yzm{display: flex;width: 410px;margin-bottom: 42px;
  .password{flex: 1;margin-right: 4px;margin-bottom: 0;width: 0;
    img{width: auto;margin-right: 26px;}
  }
  .anniu{height: 56px;width: 140px;    padding: 0;
    display: flex;background: #E8E8E8;
    align-items: center;
    justify-content: center;font-size: 20px;color: #444;}
}
</style>
